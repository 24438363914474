import styles from "./Footer.module.scss";
import Logo from "../../assets/img/black/icon/white_icon_transparent_background.png";
import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 50);
  };
  return (
    <footer className={styles}>
      <div className={styles.footer__menu}>
        <div className={styles.footer__menu__container}>
          <ul>
            <li>
              <img src={Logo} alt="Ipekten Dienstleistungen Logo"></img>
            </li>
            <li onClick={scrollToTop}>
              <Link to="/contact" className={styles.link}>
                Kontakt
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <Link to="/imprint" className={styles.link}>
                Impressum
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <Link to="/data-privacy" className={styles.link}>
                Datenschutz
              </Link>
            </li>
          </ul>
        </div>
        <span></span><div className={styles.tel}>
        <ul>
          <li>Festnetz: +49 7150 810 86 72</li>
          <li>Email: contact@ipekten.de</li>
          <li>Mobil: +49 172 31 17 110</li>
        </ul>
      </div>
      <div className={styles.address}>
        <ul>
          <li>Ipekten Dienstleistung</li>
          <li>Schellingstr. 17</li>
          <li>71282 Hemmingen</li>
        </ul>
      </div>
      </div>
      <span>©2024 Ipekten Dienstleistung</span>
    </footer>
  );
}

export default Footer;
