import styles from "./Input.module.scss";

function Input({ placeholder, type, name }) {
  return (
    <input
      className={styles.Input}
      placeholder={placeholder}
      type={type}
      name={name}
    />
  );
}

export default Input;
