import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./Contact.module.scss";
import Input from "../input/Input";
import Button from "../button/Button";

function Contact({ className }) {
  const [captchaValue, setCaptchaValue] = useState(null);

  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaValue) {
      alert("Bitte bestätige, dass du kein Roboter bist.");
      return;
    }
    const form = new FormData(event.target);
    const data = {
      email: form.get("email"),
      subject: form.get("subject"),
      message: form.get("message"),
    };

    try {
      const response = await fetch("/.netlify/functions/send-email", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        alert("Email erfolgreich gesendet!");
        window.location.reload();
      } else {
        alert("Fehler beim Senden der Nachricht.");
      }
    } catch (error) {
      alert("Ein Fehler ist aufgetreten: " + error.message);
    }
  };

  return (
    <form
      className={`${styles.Contact} ${className}`}
      name="contact"
      onSubmit={handleSubmit}
    >
      <Input type="email" name="email" placeholder="E-Mail*" />
      <Input type="text" name="subject" placeholder="Betreff*" />
      <textarea name="message" placeholder="Nachricht eingeben...*" />
      <ReCAPTCHA className={styles.ReCAPTCHA}
        sitekey="6LfDHoYpAAAAAEWWAriJ0zTU6EMiiFntLd7KguRi"
        onChange={onCaptchaChange}
      />
      <Button variant="primary" label="Senden" type="submit" />
    </form>
  );
}

export default Contact;
