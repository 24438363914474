import styles from "./Header.module.scss";
import Logo from "../../assets/img/black/icon/white_icon_transparent_background.png";
//import Dropdown from "../dropdown/Dropdown";
import LogoText from "../../assets/img/black/text/white_textlogo_transparent_background.png";
import { useState } from "react";
//import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isFixed, setIsFixed] = useState(false);
  //const [showDropdown, setShowDropdown] = useState(false);
  //const closeTimeoutId = useRef();

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 50);
  };

  useEffect(() => {
    const handleScroll = () => {
      const shouldFixHeader = window.scrollY > 500;
      setIsFixed(shouldFixHeader);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*const showDropdownMenu = () => {
    if (closeTimeoutId.current) {
      clearTimeout(closeTimeoutId.current);
    }
    setShowDropdown(true);
  };

  const hideDropdownMenu = () => {
    closeTimeoutId.current = setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };*/

  return (
    <header className={`${styles.header} ${isFixed ? styles.fixedHeader : ""}`}>
      <div className={styles.emergency}>
        <Link onClick={scrollToTop} className={styles.notfall} to={"/contact"}>
          <span>Notfall? Wir sind 24/7 für Sie da ➔</span>
        </Link>
      </div>
      <div className={styles.header__section}>
        <div onClick={scrollToTop}>
          <Link to={"/"} className={styles.logoContainer}>
            <img src={Logo} alt="Logo Ipekten Dienstleistungen" />
            <img src={LogoText} alt="Logo Ipekten Dienstleistungen" />
          </Link>
        </div>

        <nav>
          <ul>
            {
              //<li onMouseOver={showDropdownMenu} onMouseOut={hideDropdownMenu}>
              //Leistungen
              //<Dropdown isVisible={showDropdown} />
              //</li>
            }
            <li onClick={scrollToTop}>
              <Link to={"/contact"} className={styles.contact}>
                Kontakt
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
