import styles from "./DataPrivacy.module.scss";

function DataPrivacy() {
  return (
    <div className={styles.DataPrivacy}>
      <div className={styles.section__container}>
        <h1>Datenschutzerklärung</h1>
        <h2>Datenschutz auf einen Blick</h2>
        <span>
          Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer
          Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie
          ausführlich über den Umgang mit Ihren Daten.
        </span>
        <h2>1. Datenerfassung auf unserer Website</h2>
        <span>
          <b>Cookies:</b> Unsere Website verwendet Cookies, um Ihr
          Nutzungserlebnis zu verbessern. Cookies sind kleine Textdateien, die
          auf Ihrem Computer gespeichert werden und uns ermöglichen, Ihren
          Browser zu erkennen.
          <br />
          <b>Kontaktformular:</b> Wenn Sie uns über das Kontaktformular
          kontaktieren, werden die von Ihnen angegebenen Daten zwecks
          Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
          gespeichert.
        </span>
        <h2>2. Datensicherheit</h2>
        <span>
          Wir treffen technische und organisatorische Sicherheitsmaßnahmen, um
          Ihre personenbezogenen Daten gegen unbeabsichtigte oder unrechtmäßige
          Löschung, Veränderung oder gegen Verlust und gegen unberechtigte
          Weitergabe oder unberechtigten Zugriff zu schützen.
        </span>
        <h2>3. Auskunft, Löschung, Sperrung</h2>
        <span>
          Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre
          gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
          und den Zweck der Datenverarbeitung. Ebenso haben Sie das Recht auf
          Berichtigung, Sperrung oder Löschung dieser Daten.
        </span>
        <h2>4. Änderungen der Datenschutzerklärung</h2>
        <span>
          Diese Datenschutzerklärung kann sich von Zeit zu Zeit ändern. Die
          aktualisierte Version wird auf unserer Website veröffentlicht. Für
          weitergehende Informationen zum Datenschutz und zu Ihren Rechten
          können Sie sich gerne an unsere Datenschutzbeauftragten unter
          datenschutz@ipekten.de wenden. Bei weiteren Fragen oder Anliegen
          bezüglich unseres Impressums oder unserer Datenschutzerklärung stehen
          wir Ihnen gerne zur Verfügung. Kontaktieren Sie uns bitte unter
          contact@ipekten.de.
        </span>
      </div>
    </div>
  );
}

export default DataPrivacy;
