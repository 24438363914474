import styles from "./Rohrreinigung.module.scss";
import Logo from "../../assets/img/black/icon/white_icon_transparent_background.png";

function Rohrreinigung({ img, alt, title, description }) {
  return (
    <div className={styles.Rohrreinigung}>
      <div className={styles.img__container}>
        <div className={styles.overlay}>
          <img className={styles.logo} src={Logo} alt="Ipekten Dienstleistungen" />
          <div className={styles.info}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
        <img className={styles.bg} src={img} alt={alt} />
      </div>
    </div>
  );
}

export default Rohrreinigung;
