import styles from "./Leystung.module.scss";

function Leystung({ img, alt, title, description }) {
  return (
    <div className={styles.Leystung}>
      <div className={styles.img__container}>
        <h3>{title}</h3>
        <img src={img} alt={alt} />
      </div>
      <p>{description}</p>
    </div>
  );
}

export default Leystung;
