import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import NotFound from "./pages/404/NotFound";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Imprint from "./pages/imprint/Imprint";
import Cover from "./components/cover/Cover";
import ContactPage from "./pages/contactPage/ContactPage";
import DataPrivacy from "./pages/dataPrivacy/DataPrivacy";

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Header />
      <Cover />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
