import styles from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={styles.NotFound}>
      <span className={styles.Number}>404</span>
      <span>Page not found</span>
    </div>
  );
}

export default NotFound;
