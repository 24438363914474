import styles from "./Imprint.module.scss";

function Imprint() {
  return (
    <div className={styles.Imprint}>
      <div className={styles.section__container}>
        <h1>Impressum</h1>
        <span className={styles.ImprintBold}>
          Verantwortlich für den Inhalt
        </span>
        <span>Ipekten Dienstleistung</span>
        <span>Schellingstr. 17</span>
        <span>71282 Hemmingen</span>
        <span>Deutschland</span>
        <span className={styles.ImprintBold}>Kontakt</span>
        <span>Telefon: +49 172 31 17 110</span>
        <span>E-Mail: contact@ipekten.de</span>
        <span className={styles.ImprintBold}>Geschäftsführer</span>
        <span>Kürsat Mehmet Ipekten</span>
        <span className={styles.ImprintBold}>Handelsregister</span>
        <span>Handwerkskammer Stuttgart</span>
        <span className={styles.ImprintBold}>
          Umsatzsteuer-Identifikationsnummer
        </span>
        <span>DE365959215</span>
      </div>
    </div>
  );
}

export default Imprint;
